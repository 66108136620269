<template>
    <div
        v-if="!item.hide"
        class="fill-in-the-blank mb-0"
        :class="[document.show_answer_key ? 'show_answer_key' : '']"
        :style="{
            lineHeight: style.font_space + 'em',
            padding: `${(style.font_space - 1.5) * 0.5}em 0`,
        }"
    >
        <div class="item mb-0 pb-0 d-flex">
            <span
                v-if="item.numbering && item.numbering.number > 0"
                class="preview-number align-items-top"
                :class="{ 'number-hide': item.numbering.hidden }"
            >
                <NumberFormatIndex :index-info="item.numbering" resizable />
            </span>
            <div class="d-flex flex-wrap">
                <span
                    v-for="(word, index) in item.data.words"
                    :key="index"
                    class="letter_container mb-0"
                    :class="{
                        'words-pacing': /[a-zA-Z0-9]/.test(word.value) && index !== item.data.words.length - 1,
                    }"
                >
                    <template v-if="isSimpleWord">
                        <span
                            v-for="(segment, segmentIndex) in getWordSegments(word)"
                            :key="'fillintheblank-simple-word-' + segmentIndex"
                        >
                            <span
                                class="d-inline-flex justify-content-center"
                                :class="getSimpleWordSegmentClasses(segment)"
                                :style="{
                                    'min-width': getSimpleWordSegmentWidth(segment),
                                }"
                            >
                                <span v-if="!segment.blank || document.show_answer_key">
                                    {{ segment.text }}
                                </span>
                                <span v-else>&nbsp;</span>
                            </span>
                        </span>
                    </template>
                    <template v-else>
                        <span
                            v-for="(letter, letterIndex) in word.value"
                            :key="'fillintheblank-complex-word-' + index + '-letter-' + letterIndex"
                            :class="getLetterClasses(word, letterIndex)"
                            :style="{ borderColor: item.style.color }"
                        >
                            <span>{{ letter }}</span>
                        </span>
                    </template>
                </span>
            </div>
        </div>

        <div>
            <div
                v-if="item.data.multiple_choice"
                class="choices mt-2 mr-3 ml-4 grid-1"
                :class="'grid-' + item.data.number_of_columns"
            >
                <div
                    v-for="(option, index) in item.data.choices"
                    :key="`option-${index}`"
                    class="choice d-flex align-items-center"
                    :style="{
                        lineHeight: style.font_space + 'em',
                    }"
                >
                    <div
                        class="d-flex justify-content-center align-itemstcenter"
                        :style="{
                            fontSize: style.font_size + 'px',
                            height: '1.5em',
                        }"
                    >
                        <div
                            class="correct-answer-marker rounded-circle d-flex align-items-center justify-content-center p-2"
                            :class="{ 'border-danger': option.correct && document.show_answer_key }"
                        >
                            <span class="number" v-text="indexToLetter(index) + '. '"></span>
                        </div>
                    </div>
                    <div class="text-pre ml-2">{{ option.answer }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import indexToLetter from '../../mixins/IndexToLetter'
import widthOfText from '../../mixins/widthOfText'
import _ from 'lodash'
import NumberFormatIndex from '../format-helpers/NumberFormatIndex.vue'

export default defineComponent({
    name: 'FillInTheBlankPreview',
    components: {
        NumberFormatIndex,
    },
    mixins: [indexToLetter, widthOfText],
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState(['document']),
        ...mapGetters({
            style: 'document/documentStyle',
        }),
        isSimpleWord() {
            return this.item.data.type == 'whole_word' && this.item.data.answer_type !== 'letter_blanks'
        },
    },
    methods: {
        getSimpleWordSegmentWidth(segment) {
            return segment.blank ? this.widthOfText(segment.text, this.item.data.answer_type === 'none' ? 1 : 2) : 'auto'
        },
        getSimpleWordSegmentClasses(segment) {
            if (!segment.blank) {
                return []
            }

            let classes = []
            classes.push('text-danger')
            if (this.item.data.answer_type === 'letter_blanks') {
                classes.push('letter-blank')
            }
            if (this.item.data.answer_type !== 'none') {
                classes.push('blank-underline')
            }
            return classes
        },
        getLetterClasses(word, letterIndex) {
            let classes = [this.item.data.answer_type === 'none' ? 'border-0' : '', this.item.data.blank_style]

            if (!this.letterIsPartOfAnswer(word, letterIndex)) {
                return classes
            }

            classes.push('answer')
            if (this.item.data.answer_type === 'letter_blanks') {
                classes.push('letter_blank')
            }
            if (this.item.data.answer_type !== 'letter_blanks' && this.item.data.type == 'partial_word') {
                classes.push('seamless_blank')
                if (this.letterIsDisplayedAnswer(word, letterIndex)) {
                    classes.push('text-dark')
                } else {
                    classes.push('text-danger')
                    if (!this.document.show_answer_key) {
                        classes.push('hide')
                    }
                }
            }
            if (this.item.data.answer_type === 'letter_blanks') {
                if (this.letterIsDisplayedAnswer(word, letterIndex)) {
                    classes.push('text-dark')
                } else {
                    classes.push('text-danger')
                    if (!this.document.show_answer_key) {
                        classes.push('hide')
                    }
                }
            }
            return classes
        },
        getWordSegments(word) {
            if (!word.partials.length) {
                return [{ text: word.value, blank: word.partials.length }]
            }

            let head = 0
            let segments = []

            const partials = _.orderBy(word?.partials ? word.partials : [], 'start', 'asc')

            for (let i in partials) {
                if (head < partials[i].start) {
                    segments.push({
                        text: word.value.substring(head, partials[i].start),
                        blank: false,
                        head: head,
                    })
                    head = partials[i].start
                }

                segments.push({
                    text: word.value.substring(partials[i].start, partials[i].end),
                    blank: true,
                    head: head,
                })
                head = partials[i].end
            }

            if (head < word.value.length) {
                segments.push({
                    text: word.value.substring(head, word.value.length),
                    blank: false,
                    head: head,
                })
            }

            return segments
        },
        wordContainsAnswer(word) {
            return this.item.data.answer_type !== 'letter_blanks' && word.partials.length
        },
        letterIsPartOfAnswer(word, letterIdx) {
            if (!word.partials.length) return false

            for (let i in word.partials) {
                if (letterIdx >= word.partials[i].start && letterIdx < word.partials[i].end) {
                    return true
                }
            }
            return false
        },
        letterIsDisplayedAnswer(word, letterIdx) {
            return word.specific_filters?.includes(letterIdx)
        },
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';

.fill-in-the-blank {
    .item {
        padding-bottom: 1.5em;

        .number {
            flex-shrink: 0;
        }

        .blank {
            line-height: 1.2em;
            margin: 0 0.05in;
            text-align: center;
            display: inline-block;
            color: transparent;
        }
        .letter_container {
            white-space: nowrap;
            display: inline-block;

            .letter_blank,
            .letter_blank_space {
                display: inline-block;
                text-align: center;
                width: 1.5em;
                margin: 0 0.01in;

                &.hide {
                    color: transparent;
                }
            }
            .letter_blank {
                border-width: 0 0 2px 0;
                border-style: solid;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                text-decoration: none !important;
                line-height: 1.15;

                &.circle,
                &.box {
                    border-width: 2px;
                    height: 1.5em;
                }

                &.circle {
                    border-radius: 50%;
                }
                &.hide {
                    color: transparent !important;
                }
            }

            .letter_blank_space {
                width: 0.1in;
                height: 1em;
            }

            &.answer_blank {
                padding: 0 2em;
                border-bottom: 2px solid;
            }
        }
    }

    &.show_answer_key {
        .blank {
            color: $danger;
        }
        .letter_blanks {
            .letter_blank.hide {
                color: $danger;
            }
        }
    }

    .terms {
        padding-right: 0.5in;
    }

    .text-whitespace-pre {
        white-space: pre !important;
    }
}

.blank-underline {
    text-align: center;
    border-bottom: 2px solid black;
    text-decoration-color: #000;
    color: #ff0000;
    line-height: 1.15;
}
.seamless_blank,
.seamless_blank_space {
    display: inline-block;
    text-align: center;

    &.hide {
        color: transparent;
    }
}
.seamless_blank {
    border-width: 0 0 2px 0;
    border-style: solid;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    line-height: 1.15;

    &.circle,
    &.box {
        border-width: 2px;
        height: 1.5em;
    }

    &.circle {
        border-radius: 50%;
    }
    &.hide {
        color: transparent !important;
    }
}

.words-pacing {
    padding-right: 0.35rem;
}

.correct-answer-marker {
    border-radius: 50%;
    border: 1px solid transparent;
    height: 100%;
    width: 1.5em;
}

.choice {
    padding-bottom: 0.5em;
    &:last-child {
        padding-bottom: 0;
    }
}
</style>
