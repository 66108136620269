<template>
    <div
        v-if="!item.hide"
        class="mb-0"
        :style="{
            padding: `${(style.font_space - 1.5) * 0.5}em 0`,
        }"
    >
        <div class="word-scramble">
            <div key="document-item" class="item d-inline-flex mb-0 w-100">
                <span
                    v-if="item.numbering && item.numbering.number > 0"
                    class="preview-number d-flex align-items-top"
                    :class="{ 'number-hide': item.numbering.hidden }"
                >
                    <NumberFormatIndex :index-info="item.numbering" resizable />
                </span>
                <div class="d-flex term w-100">
                    <span v-if="!item.data.scramble_by_word" class="text-pre">
                        {{ scramble(item.data.term, item.display_order) }}
                    </span>
                    <div v-else>
                        <span v-for="(term, index) in item.data.term.split(' ')" :key="index" class="mr-1">
                            {{ scramble(term, item.display_order) }}
                        </span>
                    </div>
                    <div
                        v-if="item.data.type !== 2 && item.data.line_position === 'after'"
                        class="d-flex ml-2 flex-wrap"
                        :style="{ flex: 1 }"
                    >
                        <span
                            v-if="!item.data.scramble_by_word"
                            class="answer text-danger"
                            :class="item.data.term ? 'px-3' : ''"
                            :style="{
                                borderBottom: '2px solid ' + item.style.color,
                                minWidth: 'fit-content',
                                width: item.data.line_length + '%',
                            }"
                        >
                            <span
                                class="text-pre"
                                :style="{
                                    opacity: document.show_answer_key ? '1' : '0',
                                }"
                            >
                                {{ item.data.term }}
                            </span>
                        </span>
                        <div v-else>
                            <span
                                v-for="(term, index) in item.data.term.split(' ')"
                                :key="'after-' + term + index"
                                class="answer text-danger mr-1"
                                :class="term ? 'px-3' : ''"
                                :style="{
                                    opacity: document.show_answer_key ? '1' : '0',
                                }"
                            >
                                <span
                                    class="text-pre"
                                    :style="{
                                        opacity: document.show_answer_key ? '1' : '0',
                                    }"
                                >
                                    {{ term }}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <span v-if="item.data.definition" class="answer-line hint text-pre">
                {{ item.data.definition }}
            </span>
        </div>
        <div v-if="item.data.type === WORD_SCRAMBLE_TYPE_MULTIPLE" class="choices mt-2 mx-3">
            <div
                v-for="(option, index) in item.data.choices"
                :key="`option-${index}`"
                class="choice d-flex align-items-center"
            >
                <div
                    class="d-flex justify-content-center align-items-center mr-2"
                    style="height: 30px; width: 30px"
                    :class="{
                        'border border-danger rounded-circle': option.correct && document.show_answer_key,
                    }"
                >
                    <span class="number" v-text="indexToLetter(index) + '. '"></span>
                </div>
                <div class="text-pre">{{ option.answer }}</div>
            </div>
        </div>
        <div
            v-if="item.data.type === WORD_SCRAMBLE_TYPE_LINES && item.data.line_position === 'below'"
            class="mt-1 mx-4 d-flex align-items-center flex-wrap"
        >
            <span
                v-if="!item.data.scramble_by_word"
                class="d-flex border-dark px-2 text-danger"
                :style="{
                    minWidth: 'fit-content',
                    width: item.data.line_length + '%',
                    borderBottom: '2px solid black',
                }"
            >
                <span v-if="document.show_answer_key">
                    {{ item.data.term }}
                </span>
            </span>
            <div v-else>
                <span
                    v-for="(term, index) in item.data.term.split(' ')"
                    :key="'below' + term + index"
                    class="d-flex border-dark px-2 text-danger justify-content-center mr-1"
                    :style="{
                        minWidth: 'fit-content',
                        width: item.data.line_length + '%',
                        borderBottom: '2px solid black',
                    }"
                >
                    {{ term }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import indexToLetter from '../../mixins/IndexToLetter'
import { mapGetters, mapState } from 'vuex'
import { WORD_SCRAMBLE_TYPE_LINES, WORD_SCRAMBLE_TYPE_MULTIPLE } from '../../store/helpers/documentHelpers'
import NumberFormatIndex from '../format-helpers/NumberFormatIndex.vue'

export default defineComponent({
    name: 'WordScramblePreview',
    components: { NumberFormatIndex },
    mixins: [indexToLetter],
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            WORD_SCRAMBLE_TYPE_LINES,
            WORD_SCRAMBLE_TYPE_MULTIPLE,
        }
    },
    computed: {
        ...mapGetters({
            canBeShuffled: 'document/canBeShuffled',
            style: 'document/documentStyle',
        }),
        ...mapState(['document']),
    },
    watch: {
        'item.data.term': function (oldVal) {
            this.$emit('setSubtitle', oldVal)
        },
    },
    methods: {
        scramble(word, seed) {
            if (!seed) {
                seed = 1
            }

            word = word?.trim()
            if (!this.canBeShuffled(word)) {
                // can't scramble a short word!
                return word?.toLowerCase().replace(/ /g, '_')
            }

            var newWord = word
            do {
                let c = new Chance(this.item.data.shuffle_seed + seed)
                var letters = word.split('')
                newWord = c.shuffle(letters).join('')
                seed++
            } while (newWord == word)

            return newWord.toLowerCase().replace(/ /g, '_')
        },
    },
})
</script>
